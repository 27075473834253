import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogoFacebook from 'react-ionicons/lib/LogoFacebook'
import LogoInstagram from 'react-ionicons/lib/LogoInstagram'
import IosMailOpenOutline from 'react-ionicons/lib/IosMailOpenOutline'


const IndexPage = () => (
  <Layout><SEO title="Wirehead Arts" />
  <nav class="level">
  <a className="button is-large is-fullwidth" href="http://www.wireheadarts.com/">Website</a>
  </nav>
  <nav class="level">
  <a className="button is-large is-fullwidth" href="https://www.facebook.com/wireheadarts/"><LogoFacebook color="#fff" />Facebook</a>
  </nav>
  <nav class="level">
  <a className="button is-large is-fullwidth" href="https://www.instagram.com/wireheadarts/"><LogoInstagram color="#fff" />Instagram</a>
  </nav>
  <nav class="level">
  <a className="button is-large is-fullwidth" href="http://www.wireheadarts.com/mail/"><IosMailOpenOutline color="#fff" />Email</a>
  </nav>
  </Layout>
)

export default IndexPage
